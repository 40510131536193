import Axios from "axios";
import jwt from "jsonwebtoken";
import Vue from "vue";
import Vuex from "vuex";
import { BASE_URL } from "@/constants.json";

Vue.use(Vuex);


console.log(window.location.origin)

export default new Vuex.Store({
  state: {
    adURL: "https://auth.bohmtech.com/user/search",
    serverURL: `${BASE_URL}/api/email`,
    users: [],
    recipients: [
      "paula.lima@bohmtech.com",
      "jonathan.duhon@bohmtech.com",
      "cre.moore@bohmtech.com",
      "it@bohmtech.com"
    ]
  },

  mutations: {
    setUsers(state, users) {
      state.users = users;
    }
  },

  getters: {
    getUsers(state) {
      return state.users;
    }
  },

  actions: {
    async getUsers({ dispatch, commit, state }, term) {
      term = term.split("(")[0];
      const { data } = await Axios({
        method: "GET",
        url: `${state.adURL}/name/${term}`,
        headers: {
          Authorization: `Bearer ${await dispatch("generateToken")}`
        }
      });

      commit("setUsers", data.user);
    },

    async sendEmail({ state, dispatch }, data) {
      await Axios({
        method: "POST",
        url: state.serverURL,
        headers: {
          Authorization: `Bearer ${await dispatch("generateToken")}`
        },
        data: {
          recipients: state.recipients,
          subject: `New Suggestion by ${data.user}`,
          content: data.suggestion
        }
      });
    },

    async generateToken() {
      return await jwt.sign({}, "thisisasupersecretkeytolockmyjsonwebtoken", {
        expiresIn: "20 minutes"
      });
    }
  }
});
