<template>
  <v-app :style="{ backgroundColor: '#333C42' }">
    <v-main :style="{ backgroundColor: '#333C42' }">
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  })
};
</script>
